import logo from './logo.svg';
import './App.css';

//components

import FullBody from './Main';

function App() {
  
  return (
    <div className="App">
      
      <FullBody />

    </div>
  );
}

export default App;
